import React, { useEffect, useState, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ToastPopup from '../popup/ToastPopup';
import { forgotPassword } from "../Api/formApi";

export default function ForgetForm() {

    const [loader, setLoader] = useState(false);
    const [success, setSucess] = useState(false);
    const [messages, setMessages] = useState("")
    const toggleSuccess = () => setSucess(p => !p);
    const inputElement = useRef(null);

    useEffect(() => {
        if (inputElement.current) {
            inputElement.current.focus();
        }
    }, []);

    const handleSubmitFirebase = async (value) => {
        setLoader(true)
        let email = value
        await forgotPassword(email).then((res) => {
            setLoader(false)
            setMessages(res.data.message)
            toggleSuccess()
        }).catch((error) => {
            console.log(error)
        })
    }
  const DisplayingErrorMessagesSchema = Yup.object().shape({
        email: Yup.string().email('Please Enter Valid Email id').required('Email ID is Required'),
    });
    
    return (
        <div className='forget-formsection'>
            <ToastContainer />
            <ToastPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />

            {loader ? <Loader /> : null}
            <Formik
                initialValues={{
                    email: ''
                }}
                validationSchema={DisplayingErrorMessagesSchema}
                onSubmit={values => handleSubmitFirebase(values)}>
                {({ values, handleChange, errors, touched, handleSubmit }) => (
                    <Form  >
                        <Form.Group>
                            <Form.Label className="form-field-label col-lg-2 col-xl-2 col-md-2 col-sm-2 col-form-label" style={{ fontSize: "18px", fontFamily: "'DejaVuSansBold'" }}>Email<span className='error-msg'> *</span></Form.Label>
                            <Form.Control name="email" value={values.email} onChange={handleChange('email')} className="form-control form-field SignUpForm SignUpForm0 InputErrorStyle SignUPFormagent" id="email" type='text' placeholder='Email Address' ref={inputElement} />
                        </Form.Group>
                        {touched.email && errors.email &&
                            <p className='error-msg mb-0 ErrorMessage'>{errors.email}</p>
                        }
                        <Button onClick={handleSubmit} className='signup-submit-btn SignUpForm'>Submit</Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

