import * as React from 'react';
import ForgetForm from '../../components/form/ForgetForm';
import { Link } from 'gatsby';
import Seo from "../../components/seo";

const ForgetPassword = () => {
    return (
    <div className='container forgotHeight position-relative'>
      <Seo title="Forgot Password" />
      <div className='logoupdate position-absolute'>
                    <Link to="/" ><img className="register-logo" src="/images/goKeyweLogoNew.png" alt="logo" /></Link></div>
        <div className='row bgWhite' id='ForgetPasswordContainerDiv' >
            <div className='col-lg-6 col-xl-6 col-md-6 p-0' >
                <div className='register-leftside'>
                </div>
            </div>
            <div className='col-lg-6 col-xl-6 col-md-6 p-0'>
                <div className='register-rightside'>
                    <div className='register-iconsection'>
                        <p className='registration font-weight-bold ForGEtPaSSSWord'>Forgot Password</p>                       
                    </div>
                    <ForgetForm />
                </div>
            </div>
        </div>
    </div>
    )
}
export default ForgetPassword;